/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-09-22 09:34:05
 * @LastEditors: pj
 * @LastEditTime: 2022-10-08 11:14:31
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/rem'

import './plugins/element.js' // element-ui
import './style/el-theme/theme/index.css' // 自定义el主题


import './style/global.less'
import './style/reset-el.less'


// svg雪碧图相关
// import SvgSprite from 'vue-svg-sprite' // svg雪碧图
// Vue.use(SvgSprite, {
//     url: '',
// })
// var ajax = new XMLHttpRequest()
// ajax.open('GET', 'sprite.svg', true)
// ajax.send()
// ajax.onload = function (e) {
//     var div = document.createElement('div')
//     div.innerHTML = ajax.responseText
//     div.style.display = 'none'
//     document.body.insertBefore(div, document.body.childNodes[0])
// }
import Dayjs from 'dayjs' // 时间格式化
Vue.prototype.$dayjs = Dayjs


//**公用组件 */



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
