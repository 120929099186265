<template>
  <div class="home">
    <!-- <div class="header-nav">
      <el-button type="primary" size="small" @click="handleToLogin">登陆</el-button>
      <el-button type="primary" size="small" @click="handleToMenu"> 菜单页 </el-button>
    </div> -->
    <!-- <div class="old-content">
      <img alt="Vue logo" src="../../assets/logo.png">
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div> -->
    <div class="top-menu">
      <page-header></page-header>
    </div>
    <!--轮播图-->
    <div class="home-topBanner">
      <el-carousel height="890px">
        <el-carousel-item v-for="item in 1" :key="item" :interval="3000" loop>
          <img class="logo-icon" src="../../assets/designTop.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--内容区-->
    <div class="home-content">
      <!--品牌介绍-->
      <div class="home-content-introduction">
        <div class="introduction-title">- INTRODUCTION -</div>
        <div class="introduction-content">
          <div class="introduction-content-title">品牌介绍</div>
          <div class="introduction-content-text">
            <div>
              澳映（珠海）文化传播有限公司，创立于2021年珠海横琴，致力于“探索潮玩秘密，引领世界潮流”。公司成立至今旗下“得乐喜”潮玩品牌运营，“喜搭档”渠道运营，围绕艺术家帮扶、IP孵化运营、消费者权益保护、潮玩文化推广、科技潮玩创新业务孵化与产业投资领域，构建了覆盖潮流玩具全产业链的综合运营平台。
            </div>
            <div>
              为了让每一个潮玩爱好者都有一个梦想的出发点，我们竭尽所能帮助他人达成梦想。平台首创DCF协同共生IP开发生态闭环系统，把所有潮玩产业参与者有机结合以达到互相扶持、取长补短、利益共享的结果，截止2022年xx月x日公司秉着用新科技打造众创众享数字潮玩生态圈，赋能传统潮玩行业的理念已经先后与百度超级链、横琴创业谷、央视CCTV达成合作关系、也得到了广大设计师，渠道商，潮玩爱好者的鼎力支持。
            </div>
          </div>
        </div>
      </div>
      <!--内容介绍-->
      <div class="home-content-banner">
        <div class="banner-title">- CREATE A DIGITAL TOYS -</div>
        <div class="banner-content">
          <div class="banner-content-title">
            以新科技打造“众创众享”数字潮玩生态圈
          </div>
          <img src="~@/assets/banner.png" alt="" />
        </div>
      </div>
      <!--新闻区-->
      <div class="home-content-introduction">
        <div class="introduction-title">- NEWS -</div>
        <div class="introduction-content">
          <div class="introduction-content-title">公司新闻</div>
          <div class="introduction-content-news">
            <div v-for="(item, index) in newsList" :key="index">
              <div class="news-box">
                <img :src="item.backgroundUrl" alt="" class="news-box-img" />
                <div class="news-box-title">
                  {{ item.title }}
                </div>
                <div class="news-box-text">
                  {{ item.newsContent }}
                </div>
                <div class="news-box-date">
                  <div class="news-box-date-day">{{ item.day }}</div>
                  <div class="news-box-date-symbol">/</div>
                  <div class="news-box-date-month">{{ item.month }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="introduction-more">更多新闻 >></div>
      </div>

      <!--底部-->
      <div class="home-content-bottom">
        <div class="bottom-logo">
          <img src="../../assets/logo.png" alt="logo" />
        </div>
        <div class="bottom-content">
          <div class="bottom-content-logo">
            <!--微信-->
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <img src="../../assets/wechatQR.png" alt="logo" />
              </div>
              <img
                src="../../assets/wechatLogo.png"
                alt="logo"
                style="width: 30px; height: 30px; margin-right: 11px"
              />
            </el-tooltip>
            <!--微博-->
            <img
              src="../../assets/weibo.png"
              alt="logo"
              style="width: 30px; height: 30px; margin-right: 11px"
            />
            <!--小红书-->
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <img src="../../assets/xiaohongshuQR.png" alt="logo" />
              </div>
              <img
                src="../../assets/xiaohongshu.png"
                alt="logo"
                class="logo-img"
                style="width: 50px; height: 18px; margin-right: 11px"
              />
            </el-tooltip>
            <!--抖音-->
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <img src="../../assets/douyinQR.png" alt="logo" />
              </div>
              <div
                style="
                  width: 36px;
                  height: 18px;
                  margin-right: 11px;
                  display: flex;
                "
              >
                <img src="../../assets/douyinLogo1.png" alt="" />
                <img src="../../assets/douyinLogo2.png" alt="logo" />
              </div>
            </el-tooltip>
          </div>
          <div class="bottom-content-text">
            <div>加入我们</div>
            <div>|</div>
            <div>联系我们</div>
            <div>|</div>
            <div>廉洁合规</div>
          </div>
          <div class="bottom-content-text2">
            <div>珠海市香洲区横琴青年创业谷20B栋316室</div>
            <div>|</div>
            <div>Tanoshi8@163.com</div>
            <div>|</div>
            <div>TANOSHI©得樂喜NFT潮玩</div>
            <div>|</div>
            <div>粤ICP备2021177234号-2</div>
            <div>|</div>
            <div>营业执照</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import pageHeader from '@/components/page-header'

export default {
  name: 'Home',
  components: {
    pageHeader,
  },
  data() {
    return {
      bannerList: [],
      newsList: [
        {
          backgroundUrl:
            'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/gw/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221101164844.jpg',
          day: '8',
          month: '10',
          title: 'CCTV《信用中国》栏目与我司战略合作',
          newsContent:
            '2022年10月8日，中央电视台《信用中国》栏目与澳映签署战略合作协议，双方就同一个目标达成共识，相辅相成，齐心协力推动得乐喜品牌的发展。同时得乐喜首届全国潮玩IP设计大赛正式拉开帷幕，为广大潮玩设计师提供了一个开放式的舞台，在这里，设计师可以尽情的展现自我风采，得乐喜将为每个梦想家的前行之路保驾护航。',
        },
        {
          backgroundUrl:
            'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/gw/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20221101164850.png',
          day: '18',
          month: '6',
          title: '6.18得乐喜app上线发布会',
          newsContent:
            '2022年6月18日，得乐喜app上线发布会在珠海横琴举行，澳门，广州，深圳，中山等各大玩具经销商及玩具厂家参加此次发布会，同时还邀请业内知名设计师，动漫专家学者进行经验交流。此次发布会吹响了潮玩文化传播的冲锋号，“新起点”，“新模式”，“新理念”的得乐喜app上线，是聚合粉丝、设计师、厂家及经销商的重要窗口和平台，本着“协同共生，众创众享”的理念，得乐喜app会不断创新，砥砺前行，全力践行新时代赋予我们的新使命！',
        },
      ],
    }
  },
  methods: {
    handleToLogin() {
      this.$router.push({ path: '/login', replace: true })
    },
    handleToMenu() {
      this.$router.push({ path: '/main', query: { id: '666' } })
    },
  },
}
</script>

<style lang="less" scoped>
// .header-nav{}
.old-content {
  text-align: center;
}
.logo-icon {
  width: 100%;
  height: auto;
}
.home-content {
  height: auto;
  width: 100%;
  text-align: center;
  &-introduction {
    padding: 100px 0 100px 0;
    background-color: #f2f2f2;
    .introduction-title {
      font-size: 60px;
      line-height: 60px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .introduction-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-title {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 50px;
      }
      &-text {
        width: 930px;
        font-size: 20px;
        line-height: 34px;
      }
      &-news {
        display: flex;
        .news-box {
          width: 655px;
          height: 610px;
          margin: 0 12.5px 0 12.5px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          &-img {
            width: 100%;
            height: 400px;
          }
          &-title {
            width: 550px;
            font-size: 26px;
            line-height: 26px;
            font-weight: bold;
            margin: 25px 0 25px 0;
          }
          &-text {
            width: 540px;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
          &-date {
            width: 50px;
            height: 100px;
            background-color: #252525;
            color: #f2f2f2;
            position: absolute;
            left: 0;
            top: 340px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &-day {
              font-size: 24px;
              line-height: 24px;
              font-family: SourceHanSansCN;
              font-weight: bold;
              color: #f2f2f2;
            }
            &-symbol {
              font-size: 21px;
              line-height: 21px;
              font-family: SourceHanSansCN;
              color: #f2f2f2;
            }
            &-month {
              font-size: 36px;
              line-height: 36px;
              font-family: SourceHanSansCN;
              font-weight: bold;
              color: #f2f2f2;
            }
          }
        }
      }
    }
    .introduction-more {
      font-size: 20px;
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #1c1c1c;
      line-height: 20px;
      margin-top: 42px;
    }
  }
  &-banner {
    padding-top: 70px;
    background-color: #252525;
    color: #fff;
    .banner-title {
      font-size: 60px;
      font-weight: bold;
      line-height: 60px;
      margin-bottom: 20px;
    }
    .banner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-title {
        font-size: 34px;
        line-height: 34px;
        margin-bottom: 60px;
      }
    }
  }
  &-bottom {
    height: 300px;
    background: #252525;
    padding: 50px 0 0 294px;
    display: flex;
    .bottom-logo {
      width: 229px;
      height: 70px;
      background: #f2f2f2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 76px;
    }
    .bottom-content {
      font-size: 20px;
      font-family: SourceHanSansCN;
      color: #f2f2f2;
      &-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        margin-bottom: 51px;
      }
      &-text {
        line-height: 20px;
        width: 346px;
        height: 23px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 39px;
      }
      &-text2 {
        line-height: 26px;
        font-size: 20px;
        width: 644px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #777777;
      }
    }
  }
}
/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.5;
}
/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}
</style>
