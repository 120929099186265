/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2022-09-22 09:34:05
 * @LastEditors: pj
 * @LastEditTime: 2023-03-01 14:30:43
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/home.vue'),
    children: [

    ],
  },
  {
    path: '/design',
    name: 'Design',
    // component: () => import('../views/design/design.vue'),
    component: () => import('../views/design/design-over.vue'),
    children: [

    ],
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import('@/views/upload/upload.vue'),
    beforeEnter: (to, from, next) => {
      const token = sessionStorage.getItem('X-Requested-Token') || null
      const IdCard = sessionStorage.getItem('IdCard') || null
      if (token && IdCard) {
        next()
      }
    },
  },
  // {
  //   path: '/design',
  //   name: 'Design',
  //   component: () => import('../views/design/design.vue'),
  //   children: [],
  // },
  {
    path: '/relationship',
    name: 'relationship',
    component: () => import('@/views/relationship/relationship.vue'),
    children: [

    ],
  },
  {
    path: '/IdCard',
    name: 'IdCard',
    component: () => import('@/views/IdCard/IdCard.vue'),
    beforeEnter: (to, from, next) => {
      const token = sessionStorage.getItem('X-Requested-Token') || null
      const IdCard = sessionStorage.getItem('IdCard') || null
      if (token && !IdCard) {
        next()
      }
    },
  },

  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/about.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/const/login.vue'),
    beforeEnter: (to, from, next) => {
      const token = sessionStorage.getItem('X-Requested-Token') || null
      const IdCard = sessionStorage.getItem('IdCard') || null
      console.log(IdCard, 'IdCard')
      if (token && IdCard) {
        console.log('upload')
        next("/upload")
      } else if (token) {
        console.log('IdCard')
        next("/IdCard")
      } else {
        next()
      }
    },
    // afterEach: (to, from, next) => {
    //   const token = sessionStorage.getItem('X-Requested-Token') || null
    //   const IdCard = sessionStorage.getItem('IdCard') || null
    //   if (IdCard && token) {
    //     console.log('upload')
    //     next("/upload")
    //   } else {
    //     console.log('IdCard')
    //     next("/IdCard")
    //   }

    // }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/const/page-404.vue')
  },

  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/home/home.vue'),
  //   children: [

  //   ],
  // },
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})


router.beforeEach((to, from, next) => {
  next()
})

export default router
