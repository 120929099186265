<template>
  <div class="page-header">
    <div class="header-logo">
      <a class="logo" href="" title="得乐喜">
        <img class="logo-icon" src="../assets/logo.png" alt="" />
      </a>
    </div>
    <div>
      <el-menu
        :default-active="activePath"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        :router="router"
      >
        <el-menu-item
          style="font-size: 16px"
          v-for="(item, index) of menuList"
          :key="index"
          :index="item.path"
        >
          {{ item.name }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="header-user" @click="toLogin">登录/注册</div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  data() {
    return {
      router: true,
      // defaultActive: "/",
      // isShow: false,
      menuList: [
        { name: '首页', path: '/home' },
        { name: '设计大赛', path: '/design' },
        { name: '合作关系', path: '/relationship' },
        // { name: '合作渠道', path: '/channel' },
      ],
      infoCardShow: false,
    }
  },
  computed: {
    activePath() {
      return this.$route.path
    },
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  methods: {
    handleSelect(key) {
      console.log('key:', key)
      // this.isShow = this.defaultActive != key
      // window.console.log(this.isShow)
    },
    toLogin() {
      this.$router.push({ path: '/login' })
    },
    logout() {
      const setConfig = {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
      this.$confirm('此操作将退出登录, 是否继续?', '提示', setConfig)
        .then((res) => {
          console.log('点击了确定')
          this.$router.replace('/home')
        })
        .catch((err) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  .header-logo {
    margin-left: 20px;
    .logo {
      width: 162px;
      height: 51px;
      display: flex;
      // border: 1px solid red;
      .logo-icon {
        width: 100%;
      }
    }
  }
  .header-user {
    width: 162px;
    cursor: pointer;
  }
  // .header-user {
  //   display: flex;
  //   margin-right: 20px;
  //   align-items: center;
  //   width: 10px;
  //   // color: #bbb;
  //   .link {
  //     color: #666;
  //     line-height: 32px;
  //     cursor: pointer;
  //     text-decoration: none;
  //     &:hover {
  //       color: #3d91ff;
  //     }
  //     &__active {
  //       color: #3d91ff;
  //     }
  //   }
  //   .login-status {
  //     position: relative;
  //     color: #888;
  //     box-sizing: border-box;
  //     .info-name {
  //       cursor: pointer;
  //       display: flex;
  //       align-items: center;
  //       .icon-user {
  //         padding: 0 8px;
  //         fill: #888;
  //       }
  //       .name {
  //         display: inline-block;
  //         max-width: 80px;
  //         white-space: nowrap;
  //         overflow: hidden;
  //         text-overflow: ellipsis;
  //       }
  //       // .el-icon-caret-bottom {
  //       //     vertical-align: top;
  //       // }
  //       &:hover {
  //         color: #3d91ff;

  //         .icon-user {
  //           fill: #3d91ff !important;
  //         }
  //       }
  //     }
  //   }
  // }

  .info-card {
    position: absolute;
    right: 0px;
    top: 50px;
    width: 80px;
    // background: #191919;
    box-sizing: border-box;
    background: #e0e0e0;

    &__item {
      padding: 0 10px;
      height: 32px;
      font-size: 12px;
      color: #bbb;
      color: white;
      line-height: 32px;
      cursor: pointer;

      &:hover {
        color: #3d91ff;
      }
      .icon-btn {
        position: relative;
        top: 5px;
        padding: 0 8px;
        fill: #888;
      }

      &:first-child {
        padding-top: 10px;
      }
      &__link {
        line-height: 32px;
        font-size: 12px;
        color: #bbb;
        text-decoration: none;
        box-sizing: border-box;

        &:hover {
          color: #3d91ff;
        }
      }

      .router-link-active {
        color: #3d91ff;
      }
    }
    .logout {
      padding-left: 20px;
      padding-bottom: 10px;
    }
    &__line {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #515151;
    }
  }
}
</style>

<style lang="less">
.page-header {
  .el-menu--horizontal > .el-menu-item {
    float: left;
    height: 80px;
    line-height: 80px;
    margin: 0;
    border-bottom: 2px solid transparent;
    color: #888;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 2px solid #000;
  }
  // .el-menu.el-menu--horizontal {
  //   border-bottom: solid 1px #e6e6e6;
  // }
}
</style>
