import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStatus: false,
    accessToken: '',
    userInfo: {
      userName: '彭虎',
    },
  },
  getters: {
    loginStatus(state){
      return state.loginStatus
    },
    accessToken(state){
      return state.accessToken
    },
    userInfo(state){
      return state.userInfo
    }
  },
  mutations: {
    setLoginStatus(state, loginStatus){
      state.loginStatus = loginStatus
    },
    setAccessToken(state, accessToken){
      state.accessToken = accessToken
    },
    setUserInfo (state, payload) {
      state.userInfo = payload
    },
  },
  actions: {

  },
  modules: {

  }
})
